import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from '../../../../shared/error-message.service';
import { AffiliatesService } from '../../../services/affiliates.service';

@Component({
    selector: 'app-create-source',
    templateUrl: './create-source.component.html',
    styleUrls: ['./create-source.component.scss'],
})
export class CreateSourceComponent implements OnInit {
    @Input() id: any = null;
    @Input() action: string = 'create';
    @Input() sourceModel: any = {
        key: '',
        channel_id: '',
        url_callback: '',
        parameters: [],
        rate: 0,
        make_callback: false,
        enabled: false,
        configs: {
            postbacks: [],
        },
    };
    templateVariables: any = [];
    channelIdSelected: number|undefined = undefined;
    channelIdAPI: number = 2;
    channelIdPixel: number = 1;

    postbackTemplate: object = {
        channel_id: this.channelIdAPI,
        url: '',
        action_type: '',
        map_parameters: [],
    };

    postbackActionTypes: object[] = [
        {
            id: 'POSTBACK_ACCEPTED',
            name: 'Accepted',
        },
        {
            id: 'POSTBACK_REJECTED',
            name: 'Rejected',
        },
    ];

    modalReference: NgbModalRef;

    sending: boolean = false;

    constructor(
        private modal: NgbModal,
        private errorMessageService: ErrorMessageService,
        private affiliateService: AffiliatesService
    ) {}

    ngOnInit() {
        if(this.sourceModel.channel_id){
            this.channelIdSelected = this.sourceModel.channel_id;
        }
        this.affiliateService.getTemplateVariables().toPromise()
            .then(response => {
                if (response) {
                    this.templateVariables = response;
                }
            });
    }

    open(content) {
        this.modalReference = this.modal.open(content, { backdrop: 'static' });

        this.modalReference.result.then(
            (result) => {},
            (reason) => {}
        );
    }


    setChannelIdSelected(event: Event): void {
        event.preventDefault();
        const selectElement = event.target as HTMLSelectElement;
        this.channelIdSelected = Number(selectElement.value);
    }
    cleanModel() {
        this.sourceModel = {
            key: '',
            channel_id: '',
            url_callback: '',
            parameters: [],
            rate: null,
            make_callback: false,
            enabled: false,
        };
    }

    addPostback(e): void {
        e.preventDefault();
        this.sourceModel.configs.postbacks.push({
            channel_id: this.channelIdAPI,
            url: '',
            action_type: '',
            map_parameters: [],
        });
        console.log(this.sourceModel.configs);
    }
    removePostback(e, index) {
        e.preventDefault();
        this.sourceModel.configs.postbacks.splice(index, 1);
        console.log(this.sourceModel.configs);
    }

    addPostbackParameter(e, postbackIndex) {
        e.preventDefault();

        if (!this.sourceModel.configs.postbacks[postbackIndex].map_parameters) {
            this.sourceModel.configs.postbacks[postbackIndex].map_parameters = [];
        }

        this.sourceModel.configs.postbacks[postbackIndex].map_parameters.push({ key: '', value: '' });
        console.log(this.sourceModel.configs);
    }

    removePostbackParameter(e, postbackIndex, parameterIndex) {
        e.preventDefault();

        this.sourceModel.configs.postbacks[postbackIndex].map_parameters.splice(parameterIndex, 1);
        console.log(this.sourceModel.configs);
    }

    addParametr(e) {
        e.preventDefault();

        if (!this.sourceModel.parameters) {
            this.sourceModel.parameters = [];
        }

        this.sourceModel.parameters.push({ key: '', value: '' });
    }

    removeParametr(e, index) {
        e.preventDefault();

        this.sourceModel.parameters.splice(index, 1);
    }
    shouldShowPostbackConfig(): boolean {

        return this.channelIdAPI === this.channelIdSelected;
    }
    shouldShowUrlConfig(): boolean {

        return this.channelIdPixel === this.channelIdSelected;
    }

    submit(action, form) {
        if (form.valid) {
            this.sending = true;
            if (action === 'create') {
                this.affiliateService
                    .createSource(this.id, this.sourceModel)
                    .toPromise()
                    .then(
                        (response) => {
                            if (response) {
                                setTimeout(
                                    function (self) {
                                        self.errorMessageService.showSuccess(
                                            'Source created succesfully!'
                                        );
                                        self.modalReference.close();
                                    },
                                    600,
                                    this
                                );
                                this.sending = false;
                            }
                        },
                        (error) => {
                            if (error.error.error) {
                                this.errorMessageService.showError(
                                    error.error.error.show_message
                                );
                                setTimeout(
                                    function (self) {
                                        self.sendingPayment = false;
                                    },
                                    600,
                                    this
                                );
                                this.sending = false;
                            }
                        }
                    );
            }

            if (action === 'edit') {
                this.sourceModel.channel_id = String(
                    this.sourceModel.channel_id
                );
                this.affiliateService
                    .updateSource(this.sourceModel)
                    .toPromise()
                    .then(
                        (response) => {
                            if (response) {
                                setTimeout(
                                    function (self) {
                                        self.errorMessageService.showSuccess(
                                            'Source updated succesfully!'
                                        );
                                        self.modalReference.close();
                                    },
                                    600,
                                    this
                                );
                                this.sending = false;
                            }
                        },
                        (error) => {
                            if (error.error.error) {
                                this.errorMessageService.showError(
                                    error.error.error.show_message
                                );
                                setTimeout(
                                    function (self) {
                                        self.sendingPayment = false;
                                    },
                                    600,
                                    this
                                );
                                this.sending = false;
                            }
                        }
                    );
            }
        }

        console.log('No valid');
    }

    protected readonly console = console;
}
