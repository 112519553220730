import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../../services/product.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-settings-product-list',
    templateUrl: './settings-product-list.component.html',
    styleUrls: ['./settings-product-list.component.scss']
})
export class SettingsProductListComponent implements OnInit {
    constructor(public productService: ProductService, private errorMessage: ErrorMessageService) {}

    ngOnInit() {
        this.getProductList();
    }

    getProductList() {
        this.productService.list();
    }

    active(product_id) {
        if (confirm('You are sure to perform this operation?')) {
            this.productService.active(product_id).subscribe(resp => {
                if (resp['accepted'] === true) {
                    this.errorMessage.showSuccess(`El Producto ${resp.product.name} ha sido activado`);
                    this.getProductList();
                } else {
                    this.errorMessage.showSuccess(`El Producto ${resp.product.name} ha sido activado`);
                }
            });
        }
    }
}
