<form [formGroup]="productForm" style="width: 22rem;" (ngSubmit)="onFormSubmit()">

  <!-- input names -->
  <div class="md-form">
    <label for="name" class="font-weight-light">Name</label>
    <input type="text" id="name" formControlName="name" class="form-control" mdbInputDirective>
    
  </div>

  <div class="md-form">
    <label for="number" class="font-weight-light">Min Limit</label>
    <input type="number" id="min" formControlName="min" class="form-control" mdbInputDirective>
    
  </div>

  <div class="md-form">
    <label for="max" class="font-weight-light">Max Limit</label>
    <input type="number" id="max" formControlName="max" class="form-control" mdbInputDirective>
    
  </div>

  <div class="md-form">
    <label for="daily_interest_rate" class="font-weight-light">Daily Interest Rate</label>
    <input type="number" id="daily_interest_rate" formControlName="daily_interest_rate" class="form-control" mdbInputDirective>
    
  </div>

  <div class="md-form">
    <label for="withdrawal_cost_rate" class="font-weight-light">Withdrawal Cost Rate</label>
    <input type="number" id="withdrawal_cost_rate" formControlName="withdrawal_cost_rate" class="form-control" mdbInputDirective >
    
  </div>

  <div class="md-form">
    <label for="month_minimun_payment_amount" class="font-weight-light">MMPA</label>
      <input type="number" id="month_minimun_payment_amount" formControlName="month_minimun_payment_amount" class="form-control" mdbInputDirective>
      
    </div>

  <div class="md-form">
    <label for="late_fee_amount" class="font-weight-light">Late Fee</label>
    <input type="number" id="late_fee_amount" formControlName="late_fee_amount" class="form-control" mdbInputDirective>
    
  </div>

  <div class="md-form">
    <label for="max_limit_approved" class="font-weight-light">Max Limit Approved</label>
    <input type="number" id="max_limit_approved" formControlName="max_limit_approved" class="form-control" mdbInputDirective>
    
  </div>

  <div class="text-center py-4 mt-3">
    <button class="btn btn-cyan waves-light" type="submit" [disabled]="productForm.invalid" mdbWavesEffect>{{product_id ? 'Edit' : 'Create' }}</button>
  </div>

</form>