import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidJobType][formControlName],[appValidJobType][formControl],[appValidJobType][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidJobTypeDirective)}]
})
export class ValidJobTypeDirective {

  constructor() { }

validate(input: AbstractControl):{[key: string]:any} {

	let v = input.value;

	if (v !== "0") {
		return null;
    }else{
    	return {appValidJobType: false};
    }

}

}
