<app-header> </app-header>

<div class="container" style="margin-top: 50px; padding-top: 10px; text-align: center;">

    <div class="col-xs-12 col-sm-12 col-md-12 miLineaColorLight miLineaFontColor miLineaFont roboto-light" style="    border: 1px solid #69356e;">
        <h1 style=" padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 10px">
            No podemos procesar actualmente tu solicitud.
        </h1>
        <h1 style=" padding-top: 10px; padding-bottom: 10px; padding-right: 20px; padding-left: 10px">
            Vuelve a intentarlo más tarde.
        </h1>

    </div>

</div>