<form>
  <div class="form-group">
    <input type="text" placeholder="Name" class="form-control" required />
  </div>
  <div class="form-group">
    <textarea placeholder="Description" class="form-control"></textarea>
  </div>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" id="customCheck1" />
    <label class="custom-control-label" for="customCheck1">Enabled</label>
  </div>
  <div class="text-right">
    <button type="submit" class="btn btn-primary">Update</button>
  </div>
</form>
