import { Component, OnInit } from '@angular/core';
import { CreditLineService } from '../../../services/credit-line.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentBankAccountService } from '../../../services/payment-bank-account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocsService } from '../../../services/docs.service';

import { saveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-credit-line-line-info',
    templateUrl: './credit-line-line-info.component.html',
    styleUrls: ['./credit-line-line-info.component.scss'],
})
export class CreditLineLineInfoComponent implements OnInit {
    lead_id: any = null;
    creditLine: any = null;
    paymentBankCard: any = null;
    creditLineOperationTypes = null;
    creditLineOperations = null;
    contract = null;
    euform: any = null;
    fileURL = null;
    url = null;
    debtLeft = null;
    situationLine = null;
    minimunPayment = null;
    feeSummary = null;
    debtLeftSummary = null;
    loading = false;
    loading_docs = false;
    promises = [];
    leadLink;
    feesSummary = null;
    creditLineOperationsPager;
    paymentDetails;
    paymentsRequests;
    pageHref;
    activetedUser;
    isCheckView;
    constructor(
        private creditLineService: CreditLineService,
        private activatedRoute: ActivatedRoute,
        private paymentBankAccount: PaymentBankAccountService,
        private docsService: DocsService,
        private modalService: NgbModal,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.pageHref = `${location.protocol}//${location.host}${
            this.router.url.split('?')[0]
        }`;
        this.activatedRoute.params.subscribe((params) => {
            this.lead_id = params['id'];
            this.loading = true;
            this.getCreditLineWithRelationshipedEntities();
            this.getDocs(this.lead_id);
            Promise.all(this.promises).then(() => {
                this.loading = false;
            });

            this.leadLink =
                '/backoffice/loans/overview/' +
                this.lead_id +
                '/leadInfo/' +
                this.lead_id;
        });

        this.isCheckView =
            this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.view === 'check';
    }

    getBankCardList(user_id) {
        this.promises.push(
            this.paymentBankAccount
                .getListByUserId(user_id)
                .subscribe((paymentBankCard) => {
                    if (paymentBankCard.length) {
                        // console.log(paymentBankCard);
                        this.paymentBankCard = paymentBankCard;
                    }
                })
        );
    }
    getCreditLineWithRelationshipedEntities() {
        this.promises.push(
            this.creditLineService
                .getByLeadIdWithRelationshipedEntities(this.lead_id)
                .toPromise()
                .then((cl) => {
                    if (Object.values(cl).length) {
                        this.creditLine = cl;
                        this.getCreditLineOperationSummary(cl.id);
                        if (cl.user.id) {
                            this.getBankCardList(cl.user.id);
                        }
                        this.getAllDebtLeftSummary(cl.id);

                        if (this.creditLine.actived_by) {
                            this.authService.getUsers().subscribe((answer) => {
                                if (answer.data.length > 0) {
                                    this.activetedUser = answer.data.filter(
                                        (item) =>
                                            item.id ===
                                            this.creditLine.actived_by
                                    )[0];
                                }
                            });
                        }
                    }
                })
        );
    }

    getCreditLineOperationSummaryPaginated(page = 1) {
        this.getCreditLineOperationSummary(this.creditLine.id, page);
    }
    getCreditLineOperationSummary(credit_line_id, page = 1) {
        let operations = this.creditLineService.operationSummary(
            credit_line_id,
            page
        );
        this.promises.push(operations);
        operations.then((result: any) => {
            // this.creditLineOperationTypes = result.operation_type;
            if (result.data.length) {
                this.creditLineOperationsPager = result;

                const currentDate = new Date()
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '');

                this.creditLineOperations =
                    this.creditLineOperationsPager.data.map((item) => {
                        let rObj = {
                            [item.id]: {
                                open: false,
                                list: [],
                            },
                            quota: item,
                            pendingDanger:
                                item?.status === 'Pending' &&
                                item?.date.replace(/-/g, '') <= currentDate,
                        };
                        return rObj;
                    });
            }
        });
    }

    getAllDebtLeftSummary(credit_line_id) {
        this.creditLineService
            .getAllDebtLeftSummary(credit_line_id)
            .subscribe((creditLine) => {
                if (Object.values(creditLine).length) {
                    this.debtLeftSummary = creditLine;
                    this.debtLeftSummary.totalDebt =
                        Number(this.debtLeftSummary.amount) +
                        Number(this.debtLeftSummary.fee) +
                        Number(this.debtLeftSummary.late_fee_amount);
                    // this.debtLeft = creditLine.debt;
                    // this.minimunPayment = creditLine.payment_rules;
                    this.feesSummary =
                        Number(this.debtLeftSummary.fee) +
                        Number(this.debtLeftSummary.late_fee_amount);
                }
            });
    }

    open(content) {
        this.modalService.open(content, { size: 'lg' });
    }

    getDocs(lead_id) {
        this.docsService.getLeadDocs(lead_id).subscribe((docs) => {
            if (Object.values(docs).length) {
                this.contract = docs.contract;
                this.euform = docs.eu_form;
            }
        });
    }

    viewContract() {
        if (this.contract) {
            this.loading_docs = true;
            this.docsService.view(this.lead_id).subscribe((pdf) => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.loading_docs = false;
            });
        }
    }
    viewEuform() {
        if (this.euform) {
            this.loading = true;
            this.docsService.viewEuform(this.lead_id).subscribe((pdf) => {
                let file = new Blob([pdf], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                this.url = this.fileURL;
                this.loading = false;
            });
        }
    }

    downloadContract(e) {
        e.preventDefault();
        if (this.contract) {
            this.docsService.view(this.lead_id).subscribe((pdf) => {
                let file = new Blob([pdf], {
                    type: 'application/pdf',
                });
                saveAs(file, 'contract.pdf');
            });
        }
    }

    downloadEuform(e) {
        e.preventDefault();
        if (this.euform) {
            this.docsService.viewEuform(this.lead_id).subscribe((pdf) => {
                let file = new Blob([pdf], {
                    type: 'application/pdf',
                });
                saveAs(file, 'euform.pdf');
            });
        }
    }

    updateCreditline(e) {
        this.loading = true;
        this.getCreditLineWithRelationshipedEntities();
        Promise.all(this.promises).then(() => {
            this.loading = false;
        });
    }

    openQuotaPaymentList(id, index) {
        if (this.creditLineOperations[index][id].open) {
            this.creditLineOperations[index][id].open = false;
        } else {
            if (!this.creditLineOperations[index][id].list.length) {
                this.creditLineService
                    .getQuotaPaymentList(id)
                    .toPromise()
                    .then((list) => {
                        if (Object.values(list).length) {
                            this.creditLineOperations[index][id].open = true;
                            this.creditLineOperations[index][id].list = list;
                        }
                    });
            } else {
                this.creditLineOperations[index][id].open = true;
            }
        }
    }

    openQuotaPaymentDetails(id, paymentModal) {
        this.creditLineService
            .getPaymentDetail(id)
            .toPromise()
            .then((answer) => {
                if (Object.values(answer).length) {
                    this.paymentDetails = answer;
                    this.modalService.open(paymentModal, { size: 'lg' });
                }
            });
    }
    openPaymentsRequests(id, modal) {
        this.creditLineService
            .getPaymentsRequests(id)
            .toPromise()
            .then((answer) => {
                if (Object.values(answer).length) {
                    this.paymentsRequests = answer;
                    // console.log("paymentsRequest", this.paymentsRequests);
                    this.modalService.open(modal, { size: 'lg' });
                }
            });
    }

    formatDate(stringDate) {
        let formatDateString;
        if (stringDate) {
            formatDateString = new Date(stringDate.replace(/-/g, '/'));
        }
        return formatDateString;
    }

    quotaRectification() {
        this.updateCreditline(true);
    }

    disableRecurrentPayment(bank_card_id, disable, index): void
    {
        this.paymentBankAccount
            .disableRecurrentPaymentByBankCard(this.creditLine.user_id, bank_card_id, !disable)
            .subscribe((bankCard) => {
                // console.log(index);
                // console.log(bankCard);
                if(this.paymentBankCard){
                    // console.log(this.paymentBankCard[index]);
                    this.paymentBankCard[index] = bankCard;
                }
            });
    }

    disableRecurrentPaymentForAllBankCard(): void
    {
        const confirmDisable= confirm('Are you sure you want to disable all bank cards to avoid recurring charges?');
        if(confirmDisable) {
            this.paymentBankAccount
                .disableRecurrentPaymentForAllBankCardByUser(this.creditLine.user_id, true)
                .subscribe((bankCards) => {
                    // console.log(bankCards);
                    this.paymentBankCard = bankCards;
                });
        }
    }
}
