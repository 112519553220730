import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidStreetType][formControlName],[appValidStreetType][formControl],[appValidStreetType][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidStreetTypeDirective)}]
})
export class ValidStreetTypeDirective {

  constructor() { }

validate(input: AbstractControl):{[key: string]:any}{

	let v= input.value;

	if (v !== "0") {
		return null;
    }else{
    	return {appValidStreetType: false};
    }

}

}
