import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-info-address-tracking',
  templateUrl: './lead-info-address-tracking.component.html',
  styleUrls: ['./lead-info-address-tracking.component.css']
})
export class LeadInfoAddressTrackingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
