import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidCreditCard][formControlName],[ValidCreditCard][formControl],[ValidCreditCard][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidCreditCardDirective)}]
})
export class ValidCreditCardDirective {

  constructor() { }

  validate(input: AbstractControl):{[key: string]:any} {

  	var newInput: string[];
  	var naux: string;
  	var newN: string;

  	var valid:boolean = false;
       
	if (input.value){

		if (input.value.length === 16) {

			console.log(input.value);

			//repensar

			var cast = input.value.split('');
			for (var i=0; i<cast.length; i++){
			    console.log(cast[i]);
			    naux=cast[i];
			    if (Number(naux)%2 === 1) {
			    	naux=String(Number(cast[i])*2);
			    }
			    newInput[i] = naux;
			}

			for (var i = 0; i<newInput.length; i++) {
				if (Number(newInput[i]) >= 10) {
					var ndobule = newInput[i].split('')
					for (var j = 0; j<ndobule.length; j++) {
						naux = ndobule[j];
						newN = String(Number(naux) + Number(newN));
					}
					newInput[i] = newN;
				}
			}
			
			for (var i = 0; i<newInput.length; i++) {
				naux = newInput[i];
				newN = String(Number(naux) + Number(newN));
			}

			if (Number(newN)%10 === 0) {
				return null;
			}else{
				return {ValidCreditCard: false}
			}
		}
	}
}
}
