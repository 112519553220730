import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
selector: '[appValidOtherPhone][formControlName],[appValidOtherPhone][formControl],[appValidOtherPhone][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidOtherPhoneDirective)}]
})
export class ValidOtherPhoneDirective {

  constructor() { }

validate(input: AbstractControl):{[key: string]:any} {

	let v = input.value;

    // control value (e.g. password)
    let e = input.root.value.mobile_phone;

    // value  equal
    if (e && v !== e) {
       return null;
    }else{
    	return {appValidOtherPhone: false};
    }

}


}
