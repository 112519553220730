import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProductService {
  products: any = null;
  constructor(private http: HttpClient) { }

  get(product_id): Observable<any>
  {
    return this.http.get(environment.api_url + `/product/${product_id}/show`);
  }
  create(product): Observable<any>
  {
    return this.http.post(environment.api_url + '/product/create', product);
  }
  active(product_id): Observable<any>
  {
    return this.http.post(environment.api_url + `/product/${product_id}/active`, {});
  }
  edit(product_id, product): Observable<any>
  {
    return this.http.post(environment.api_url + `/product/${product_id}/edit`, product);
  }
  list()
  {
    return this.http.get(environment.api_url + `/product/list`).subscribe(
      products => {
        if(Object.values(products).length){
          this.products = products;
        }
      }
    );
  }
}
