import { Component, OnInit, Input } from '@angular/core';
import { LeadService } from '../../../services/lead.service';

@Component({
  selector: 'app-contact-phone-form',
  templateUrl: './contact-phone-form.component.html',
  styleUrls: ['./contact-phone-form.component.scss']
})
export class ContactPhoneFormComponent implements OnInit {

  @Input() title: string = '';
  @Input() workPhoneTwo: Boolean = false;
  @Input() additionalPhoneOne: Boolean = false;
  @Input() additionalPhoneTwo: Boolean = false;

  @Input() lead_id: number;

  constructor(private leadService: LeadService) { }

  ngOnInit() {

  }
  add(phone_number)
  {
    if(this.workPhoneTwo){
      this.addPhone({work_phone_two:phone_number});
    }
    if(this.additionalPhoneOne){
      this.addPhone({additional_phone_one:phone_number});
    }
    if(this.additionalPhoneTwo){
      this.addPhone({additional_phone_two:phone_number});
    }
  }
  addPhone(phone: Object)
  {
    this.leadService.addPhone(this.lead_id, phone).subscribe(
      resp => {
      }
    );
  }
}
