import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-setting-users',
  templateUrl: './setting-users.component.html',
  styleUrls: ['./setting-users.component.scss']
})
export class SettingUsersComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
    
  }

}
