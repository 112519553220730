import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit, OnChanges {
    @Input() error: any;
    constructor() {}

    ngOnInit() {}
    ngOnChanges() {
        setInterval(() => {
            this.error = '';
        }, 8000);
    }
}
