import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-lead-info-scoring-graphic',
    templateUrl: './lead-info-scoring-graphic.component.html',
    styleUrls: ['./lead-info-scoring-graphic.component.scss']
})
export class LeadInfoScoringGraphicComponent implements OnInit, OnChanges {
    @Input() bad_start: number = 0;
    @Input() bad_end: number = 0;
    @Input() regular_start: number = 0;
    @Input() regular_end: number = 0;
    @Input() good_start: number = 0;
    @Input() good_end: number = 0;
    @Input() very_good_start: number = 0;
    @Input() very_good_end: number = 0;
    @Input() base: number = 0;
    @Input() demographic: number = 0;
    @Input() bank: number = 0;
    @Input() score: number = 0;
    @Input() score_label: string = '';

    constructor() {}

    ngOnInit() {}
    ngOnChanges() {}
}
