import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AffiliatesService {
    channels: any;
    actionTypes = [
        {
            id: 'POSTBACK_ACCEPTED',
            name: 'Accepted',
        },
        {
            id: 'POSTBACK_REJECTED',
            name: 'Rejected',
        },
    ];

    constructor(private http: HttpClient) {}

    getAffiliatesList(params): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/list?${params}`);
    }

    createAffiliate(affiliate): Observable<any> {
        return this.http.post(environment.api_url + `/affiliate/create`, affiliate);
    }

    showAffiliate(id): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/${id}/show`);
    }

    updateAffiliate(id, data): Observable<any> {
        return this.http.post(environment.api_url + `/affiliate/${id}/update`, data);
    }

    getSources(): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/source/list`);
    }

    showSource(id): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/source/${id}/show`);
    }

    createSource(id, data): Observable<any> {
        return this.http.post(environment.api_url + `/affiliate/${id}/source/create`, data);
    }

    updateSource(data): Observable<any> {
        return this.http.post(environment.api_url + `/affiliate/source/${data.id}/update`, data);
    }

    getChannels(): void {
        if (!this.channels) {
            this.http
                .get(environment.api_url + `/affiliate/channel/list`)
                .toPromise()
                .then(response => {
                    if (response) {
                        this.channels = response;
                    }
                });
        }
        return;
    }

    getTemplateVariables(): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/url_template/list`);
    }

    getStatistic(params): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/statistic/list?${params}`);
    }

    getAffiliateLog(id, params): Observable<any> {
        return this.http.get(environment.api_url + `/affiliate/${id}/log/list?${params}`);
    }
}
