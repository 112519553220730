import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidAmountPayment][formControlName],[ValidAmountPayment][formControl],[ValidAmountPayment][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidAmountPaymentDirective)}]
})
export class ValidAmountPaymentDirective implements Validator{

  @Input() maxAmount:number;

  constructor() { }

  validate(input: AbstractControl):{[key: string]:any} {

  	if (input.value !== '' && Number(input.value) <=this.maxAmount && Number(input.value) >10 ) {
  		
  		return null;
  	}
  	return {ValidAmountPayment : false};
  }

}
