import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidPin][formControlName],[ValidPin][formControl],[ValidPin][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidPinDirective)}]
})
export class ValidPinDirective implements Validator {

  constructor() { }

  validate(input: AbstractControl):{[key: string]:any} {

  	let v = input.root.value.password;

    let e = input.value;

    console.log(v);
    console.log(e);

    if (this.hasValue(e) && this.hasValue(v)) {
    	if (e.length >=6 && v.length >=6) {
    	
	    	if (e.match('([A-Za-z]*[0-9]+[A-Za-z]*){1,6}') && v.match('([A-Za-z]*[0-9]+[A-Za-z]*){1,6}') && e===v) {
	    		return null;
	    	}else{
	    		return {ValidPin: false};
	    	}
	    }else{
	    	return {ValidPin: false};
	    }
    }else{
    	return {ValidPin: false};
    }

  }

	hasValue(input){
		return (input!=null && input!=='');  // != not strict, compare with null and undefined
	}


}
