<button type="button" class="btn btn-primary relative waves-light"  mdbWavesEffect>Add</button>
<div  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <form #phoneForm="ngForm" >
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" >
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">{{title}}</h4>
            </div>
            <div class="modal-body">
                  <div class="md-form">
                    <input mdbInputDirective data-error="This field is required" data-success="" type="text" id="form1" class="form-control" value="" #phoneNumber required appValidOtherPhone pattern="[6|7|9][0-9]{8}">
                    <label for="form1" class="">Example label</label>
                  </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-light" aria-label="Close"  mdbWavesEffect>Close</button>
                <button type="button" class="btn btn-primary relative waves-light" (click)="add(phoneNumber.value);phoneNumber.value='';" mdbWavesEffect>Save changes</button>
            </div>
          </form>
        </div>
    </div>
</div>