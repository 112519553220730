import {Directive, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';
 
@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {

    constructor(private _elementRef: ElementRef) {
    }

    private firstTime =true;
 
    @Output()
    public clickOutside = new EventEmitter();
 
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        let stayOpen;
        let open = document.getElementById("myNavMyAccount").style.display === "block" || document.getElementById("myNav").style.display === "block";

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (open) {  // cuando se ejecuta la directiva, el componente ya ha puesto el height del menu = 100%
                     // entonces, si ya esta a 100%, es que está abiertp


            if (this.firstTime) { // si pinchan fuera por primera vez, es el click para abrir el menu
            this.firstTime=false; 

            }else{        // si no es la primera vez, es que ya estaba abierto y han pinchado fuera

                this.clickOutside.emit(null); // se debe cerrar
                this.firstTime = true;
             // si pinchan dentro no hacemos nada

        }
            
        }else{    // si está cerrado y salta la directiva es por que se ha cerrado desde el boton del menu
            this.firstTime = true; // entonces hay que dejarlo como si fuera la primera vez
        }                           // para que el proximo primer click cuando este abierto abra el menú
    }
}