<div *appHasPermissions="['marketing']">
    <div class="row justify-content-between mb-3">
        <div class="col-6">
            <h2 class="mb-0">Affiliate Info</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
            <div class="card mb-3" style="height: 100%">
                <div class="card-header">
                    <h5 class="card-header-title">
                        Information <span (click)="openEdit(edit, 'information')">Edit</span>
                    </h5>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Company Name</strong><span>{{ affiliate?.name }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>VAT Number</strong><span>{{ affiliate?.information_cif }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Address</strong><span>{{ affiliate?.information_address }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Zip Code</strong><span>{{ affiliate?.information_zip_code }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>City</strong><span>{{ affiliate?.information_city }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Country</strong><span>{{ affiliate?.information_country }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
            <div class="card mb-3" style="height: 100%">
                <div class="card-header">
                    <h5 class="card-header-title">Contacts <span (click)="openEdit(edit, 'contacts')">Edit</span></h5>
                </div>
                <div class="card-body" style="max-height: 230px; overflow: auto;">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Name</strong><span>{{ affiliate?.contact_one_name }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Phone Mobile</strong><span>{{ affiliate?.contact_one_mobile_phone }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Email</strong><span>{{ affiliate?.contact_one_email }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Role</strong><span>{{ affiliate?.contact_one_role }}</span>
                        </li>

                        <ng-template
                            [ngIf]="
                                affiliate?.contact_two_name ||
                                affiliate?.contact_two_mobile_phone ||
                                affiliate?.contact_two_email ||
                                affiliate?.contact_two_role
                            "
                        >
                            <li class="list-group-item p-0">
                                <hr />
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Name</strong><span>{{ affiliate?.contact_two_name }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Phone Mobile</strong><span>{{ affiliate?.contact_two_mobile_phone }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Email</strong><span>{{ affiliate?.contact_two_email }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Role</strong><span>{{ affiliate?.contact_two_role }}</span>
                            </li>
                        </ng-template>

                        <ng-template
                            [ngIf]="
                                affiliate?.contact_three_name ||
                                affiliate?.contact_three_mobile_phone ||
                                affiliate?.contact_three_email ||
                                affiliate?.contact_three_role
                            "
                        >
                            <li class="list-group-item p-0">
                                <hr />
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Name</strong><span>{{ affiliate?.contact_three_name }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Phone Mobile</strong><span>{{ affiliate?.contact_three_mobile_phone }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Email</strong><span>{{ affiliate?.contact_three_email }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Role</strong><span>{{ affiliate?.contact_three_role }}</span>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
            <div class="card mb-3" style="height: 100%">
                <div class="card-header">
                    <h5 class="card-header-title">Terms <span (click)="openEdit(edit, 'terms')">Edit</span></h5>
                </div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Lead Condition</strong><span>{{ affiliate?.term_lead_condition }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Value</strong><span>{{ affiliate?.term_value }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Payment Terms</strong><span>{{ affiliate?.term_payment }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Active</strong><span>{{ affiliate?.enabled ? 'On' : 'Off' }}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                            <strong>Statistics Email</strong><span>{{ affiliate?.term_statistic_email }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ng-template ngFor let-index="index" let-source [ngForOf]="sources">
            <div class="col-12 col-sm-6 col-md-4 mb-3">
                <div class="card mb-3" style="height: 100%">
                    <div class="card-header">
                        <h5 class="card-header-title">
                            Source {{ index + 1 }}
                            <app-create-source [action]="'edit'" [sourceModel]="source"></app-create-source>
                        </h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Source</strong><span>{{ source?.key }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Tracking URL</strong><span>-</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Callback</strong><span>{{ source?.url_callback }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>Channel</strong><span>{{ getChannelName(source?.channel_id)}}</span>
                            </li>
                            <div *ngIf="source?.configs?.postbacks?.length > 0">
                                <ng-template ngFor let-postback [ngForOf]="source?.configs?.postbacks" let-i="index">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Action</strong><span>{{ getActionType(postback?.action_type) }}</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <strong>Url</strong><span>{{ postback?.url }}</span>
                                    </li>
                                </ng-template>
                            </div>
                            <li *ngIf="affiliate.api && isApiType(source.channel_id)" class="list-group-item d-flex justify-content-between align-items-center">
                                <strong>token</strong><span>{{ affiliate?.api_token_plain}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
            <app-create-source [id]="id"></app-create-source>
        </div>
    </div>
</div>

<ng-template #edit let-c="close">
    <div class="modal-header">
        <h4 class="modal-title">{{ modalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="c()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div *ngIf="form" class="row">
                <ng-template ngFor let-index="index" let-input [ngForOf]="form">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input
                                *ngIf="input.type !== 'checkbox'"
                                [placeholder]="input.label"
                                class="form-control"
                                [type]="input.type"
                                [name]="input.model"
                                [(ngModel)]="input.value"
                            />
                            <div *ngIf="input.type === 'checkbox'" class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="enabled"
                                    [name]="input.model"
                                    [(ngModel)]="input.value"
                                />
                                <label class="custom-control-label" for="enabled">{{ input.label }}</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="modalTitle === 'Contacts' && (index + 1) % 4 === 0 && index + 1 !== 12" class="col-12">
                        <hr class="mt-0" />
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button [disabled]="sending" class="btn btn-primary" (click)="updateAffiliate()">
            {{ sending ? 'Saving...' : 'Save' }}
        </button>
    </div>
</ng-template>
