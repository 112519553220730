<div *appHasPermissions="['debtrecovery-loan-due']">
    <div class="row justify-content-between mb-3">
        <div class="col-12 col-lg-2">
            <h2 class="mb-0">Due Manager</h2>
            <a [href]="exportLink" (click)="getExportLink($event)">Export</a>
        </div>
        <div class="col-12 col-lg-10">
            <app-data-page-nav [itemsList]="menu"></app-data-page-nav>
        </div>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive">
        <table class="table table-hover">
            <!-- row -->
            <thead>
                <tr>
                    <th
                        *ngIf="
                            selectedPageName !== 'new' &&
                            selectedPageName !== 'all'
                        "
                    >
                        Date
                    </th>
                    <th>Due Date</th>
                    <th
                        *ngIf="
                            selectedPageName === 'new' ||
                            selectedPageName === 'payment_incident' ||
                            selectedPageName === 'all'
                        "
                    >
                        Created
                    </th>
                    <th>Loan</th>
                    <th
                        *ngIf="
                            selectedPageName === 'payment_incident' ||
                            selectedPageName === 'all'
                        "
                    >
                        Amount
                    </th>
                    <th
                        *ngIf="
                            selectedPageName === 'payment_commitment' ||
                            selectedPageName === 'payment_undone' ||
                            selectedPageName === 'payment_done'
                        "
                    >
                        €
                    </th>
                    <th
                        *ngIf="
                            selectedPageName === 'payment_commitment' ||
                            selectedPageName === 'payment_undone' ||
                            selectedPageName === 'payment_done'
                        "
                    >
                        Weighted, %
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        Total Debt
                    </th>
                    <th>Debt Left</th>
                    <th
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        Paid
                    </th>
                    <th
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        %
                    </th>
                    <th>Card Status</th>
                    <th>DNI/NIE</th>
                    <th
                        *ngIf="
                            selectedPageName !== 'new' &&
                            selectedPageName !== 'payment_incident' &&
                            selectedPageName !== 'all'
                        "
                    >
                        Full Name
                    </th>
                    <th>Job Role</th>
                    <th *ngIf="selectedPageName === 'payment_incident'">
                        Incident Type
                    </th>
                    <th>Debt Collector</th>

                    <ng-template
                        [appHasPermissions]="[
                            'debtrecovery-due-manager-reassign'
                        ]"
                    >
                        <th
                            *ngIf="
                                selectedPageName === 'new' ||
                                selectedPageName === 'all'
                            "
                        >
                            Change
                        </th>
                    </ng-template>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td
                        *ngIf="
                            selectedPageName !== 'new' &&
                            selectedPageName !== 'all'
                        "
                    >
                        <app-daterange-filter
                            fieldName="date"
                            [start]="filters.date_start"
                            [end]="filters.date_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <app-daterange-filter
                            fieldName="loan_due_date"
                            [start]="filters.loan_due_date_start"
                            [end]="filters.loan_due_date_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName === 'new' ||
                            selectedPageName === 'payment_incident' ||
                            selectedPageName === 'all'
                        "
                    >
                        <app-daterange-filter
                            fieldName="loan_activation_date"
                            [start]="filters.loan_activation_date_start"
                            [end]="filters.loan_activation_date_end"
                            (changeDate)="onChangeDate($event)"
                        ></app-daterange-filter>
                    </td>
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Loan code"
                            name="loan_code_str"
                            [(ngModel)]="filters.loan_code_str"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName === 'payment_incident' ||
                            selectedPageName === 'all'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount"
                            name="loan_amount"
                            [(ngModel)]="filters.loan_amount"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName === 'payment_commitment' ||
                            selectedPageName === 'payment_undone' ||
                            selectedPageName === 'payment_done'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="€"
                            name="commitment_amount"
                            [(ngModel)]="filters.commitment_amount"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName === 'payment_commitment' ||
                            selectedPageName === 'payment_undone' ||
                            selectedPageName === 'payment_done'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Weighted"
                            name="weighted"
                            [(ngModel)]="filters.weighted"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        <app-dropdown-range
                            [start]="filters.loan_total_debt_start"
                            [end]="filters.loan_total_debt_end"
                            [placeholder]="'Total Debt'"
                            [name]="'loan_total_debt'"
                            (changeRange)="onChangeRange($event)"
                        ></app-dropdown-range>
                    </td>
                    <td>
                        <app-dropdown-range
                            [start]="filters.loan_debt_left_start"
                            [end]="filters.loan_debt_left_end"
                            [name]="'loan_debt_left'"
                            (changeRange)="onChangeRange($event)"
                        ></app-dropdown-range>
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Paid"
                            name="amount_paid"
                            [(ngModel)]="filters.amount_paid"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'payment_commitment' &&
                            selectedPageName !== 'payment_undone' &&
                            selectedPageName !== 'payment_done'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="%"
                            name="percent"
                            [(ngModel)]="filters.percent"
                        />
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            type="text"
                            name="card_status_id"
                            [(ngModel)]="filters.card_status_id"
                        >
                            <option value="">Select status</option>
                            <option
                                *ngFor="
                                    let key of filterLists?.cardStatus?.list
                                        | keys
                                "
                                value="{{ key }}"
                            >
                                {{ (filterLists?.cardStatus?.list)[key] }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <td
                        *ngIf="
                            selectedPageName !== 'new' &&
                            selectedPageName !== 'payment_incident' &&
                            selectedPageName !== 'all'
                        "
                    >
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Full Name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="job_type_id"
                            [(ngModel)]="filters.job_type_id"
                        >
                            <option value="">Select role</option>
                            <option
                                *ngFor="
                                    let key of filterLists?.jobRole?.list
                                        ?.lead_job_type | keys
                                "
                                value="{{ key }}"
                            >
                                {{
                                    (filterLists?.jobRole?.list?.lead_job_type)[
                                        key
                                    ]
                                }}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="selectedPageName === 'payment_incident'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            type="text"
                            name="tracking_type_incident_id"
                            [(ngModel)]="filters.tracking_type_incident_id"
                        >
                            <option value="">Select type</option>
                            <option
                                *ngFor="
                                    let key of filterLists?.traking?.list
                                        ?.incidents | keys
                                "
                                value="{{ key }}"
                            >
                                {{
                                    (filterLists?.traking?.list?.incidents)[key]
                                }}
                            </option>
                        </select>
                    </td>
                    <td>
                        <select
                            (change)="getDataOnFilterSubmit()"
                            type="text"
                            name="debt_collector_id"
                            [(ngModel)]="filters.debt_collector_id"
                        >
                            <option value="">Select Collector</option>

                            <option
                                *ngFor="let user of users?.actived"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                            <option *ngIf="users?.blocked.length > 0" disabled>
                                ──────────
                            </option>
                            <option
                                *ngFor="let user of users?.blocked"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                            <option disabled>──────────</option>
                            <option value="0">Not Assigned</option>
                        </select>
                    </td>
                    <ng-template
                        [appHasPermissions]="[
                            'debtrecovery-due-manager-reassign'
                        ]"
                    >
                        <td
                            *ngIf="
                                selectedPageName === 'new' ||
                                selectedPageName === 'all'
                            "
                        ></td>
                    </ng-template>
                </tr>
                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr
                        *ngFor="let due of tableData?.data"
                        [ngClass]="due?.is_customer ? 'table-success' : ''"
                    >
                        <td
                            *ngIf="
                                selectedPageName !== 'new' &&
                                selectedPageName !== 'all'
                            "
                        >
                            {{ due?.date }}
                        </td>
                        <td>{{ due?.loan_due_date }}</td>
                        <td
                            *ngIf="
                                selectedPageName === 'new' ||
                                selectedPageName === 'payment_incident' ||
                                selectedPageName === 'all'
                            "
                        >
                            {{ due?.loan_activation_date }}
                        </td>
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(due?.lead_id)"
                                >{{ due?.loan_code_str }}</a
                            >
                        </td>
                        <td
                            *ngIf="
                                selectedPageName === 'payment_incident' ||
                                selectedPageName === 'all'
                            "
                        >
                            {{ due?.loan_amount | currency: 'EUR' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName === 'payment_commitment' ||
                                selectedPageName === 'payment_undone' ||
                                selectedPageName === 'payment_done'
                            "
                        >
                            {{ due?.commitment_amount | currency: 'EUR' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName === 'payment_commitment' ||
                                selectedPageName === 'payment_undone' ||
                                selectedPageName === 'payment_done'
                            "
                        >
                            {{ due?.weighted | percent }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'payment_commitment' &&
                                selectedPageName !== 'payment_undone' &&
                                selectedPageName !== 'payment_done'
                            "
                        >
                            {{ due?.total_debt | currency: 'EUR' }}
                        </td>
                        <td>{{ due?.loan_debt_left | currency: 'EUR' }}</td>
                        <td
                            *ngIf="
                                selectedPageName !== 'payment_commitment' &&
                                selectedPageName !== 'payment_undone' &&
                                selectedPageName !== 'payment_done'
                            "
                        >
                            {{ due?.amount_paid | currency: 'EUR' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName !== 'payment_commitment' &&
                                selectedPageName !== 'payment_undone' &&
                                selectedPageName !== 'payment_done'
                            "
                        >
                            {{ due?.percent | percent }}
                        </td>
                        <td>{{ due?.bank_card_status }}</td>
                        <td>{{ due?.personalid }}</td>
                        <td
                            *ngIf="
                                selectedPageName !== 'new' &&
                                selectedPageName !== 'payment_incident' &&
                                selectedPageName !== 'all'
                            "
                        >
                            {{ due?.full_name }}
                        </td>
                        <td>
                            {{ due?.job_role }}
                        </td>
                        <td *ngIf="selectedPageName !== 'payment_incident'">
                            {{
                                due?.debt_collector_name
                                    ? due?.debt_collector_name
                                    : 'Not assigned'
                            }}
                        </td>

                        <td *ngIf="selectedPageName === 'payment_incident'">
                            {{ incident?.tracking_type_incident_name }}
                        </td>
                        <td *ngIf="selectedPageName === 'payment_incident'">
                            {{ due?.debt_collector_name }}
                        </td>
                        <ng-template
                            [appHasPermissions]="[
                                'debtrecovery-due-manager-reassign'
                            ]"
                        >
                            <td
                                *ngIf="
                                    selectedPageName === 'new' ||
                                    selectedPageName === 'all'
                                "
                            >
                                <i
                                    _ngcontent-c10=""
                                    class="fa fa-cog"
                                    (click)="reassign(due, reassignModal)"
                                ></i>
                            </td>
                        </ng-template>
                    </tr>
                </ng-template>

                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>

<ng-template #reassignModal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="c()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <h4>Current Debt Collector</h4>
                <h5 class="text-primary">
                    {{
                        activeDue?.debt_collector_name
                            ? activeDue?.debt_collector_name
                            : 'No assigned'
                    }}
                </h5>
            </div>
            <div class="col-6">
                <form
                    #fSpecific="ngForm"
                    (ngSubmit)="submitPortfolio(fSpecific, specificOnes)"
                    novalidate
                >
                    <div class="form-group">
                        <label class="h4" for="assignedTo" class="text-primary"
                            >Assign To</label
                        >
                        <select
                            id="assignedTo"
                            name="debt_collector_id_to"
                            class="form-control"
                            [(ngModel)]="specificOnes.debt_collector_id_to"
                            #assignedTo="ngModel"
                            required
                        >
                            <option value="">Select collector</option>
                            <ng-template
                                ngFor
                                let-user
                                [ngForOf]="users?.actived"
                            >
                                <option
                                    *ngIf="
                                        user?.id !==
                                        activeDue?.debt_collector_id
                                    "
                                    value="{{ user?.id }}"
                                >
                                    {{ user?.name }}
                                </option>
                            </ng-template>
                        </select>
                        <input
                            type="hidden"
                            name="loan_ids"
                            [(ngModel)]="specificOnes.loan_ids"
                            #loans="ngModel"
                        />
                        <small
                            *ngIf="assignedTo?.invalid && assignedTo?.touched"
                            class="text-danger"
                        >
                            Select collector
                        </small>
                    </div>
                    <div class="form-group text-right">
                        <button
                            type="submit"
                            [disabled]="fSpecific.invalid"
                            class="btn btn-primary"
                        >
                            Done
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
