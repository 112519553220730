<table class="table table-striped table-responsive">
    <thead>
        <tr>
            <th>Product</th>
            <th>Created</th>
            <th>Visible</th>
            <th>Min Limit</th>
            <th>Max Limit</th>
            <th>DIR</th>
            <th>WC</th>
            <th>MMPA</th>
            <th>Late Fee</th>
            <th>Max Limit Approved</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of productService.products">
            <th scope="row">{{ product?.name }}</th>
            <td>{{ product?.created_at }}</td>
            <td>
                {{ product?.visible ? 'Yes' : 'No' }}
                <i
                    class="fa fa-check"
                    *ngIf="!product?.visible"
                    (click)="active(product.id)"
                ></i>
            </td>
            <td>{{ product?.min }}</td>
            <td>{{ product?.max }}</td>
            <td>{{ product?.daily_interest_rate }}</td>
            <td>{{ product?.withdrawal_cost }}</td>
            <td>{{ product?.month_minimun_payment_amount }}</td>
            <td>{{ product?.late_fee_amount }}</td>
            <td>{{ product?.max_limit_approved }}</td>
            <td>
                <i
                    class="fa fa-pencil"
                    [routerLink]="
                        '/backoffice/settings/product_overview/create_product/' +
                        product?.id
                    "
                ></i>
            </td>
        </tr>
    </tbody>
</table>
