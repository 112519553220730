import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';


@Directive({
  selector: '[SelectBankacc][formControlName],[SelectBankacc][formControl],[SelectBankacc][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => SelectBankaccDirective)}]
})
export class SelectBankaccDirective {

  constructor(@Attribute('validateEqual')  public validateEqual: string ) { }

  validate(input: AbstractControl):{[key: string]:any} {

  	// self value (bank_account)
    let v = input.root.value.bank_account;

    // control value (bank_account_2)
    let e = input.root.value.bank_account_2;

    // value  equal;
    if (e && v === e) {
       return null;
    }else{
    	return {appSelectBankacc: false};
    }

  
}

}
