
<div style="margin-left: 30px">
  <div class="row">
    <div class="col-md-4" style="border: 1px">
      <div style="border: 1px; border-color: black">
        <h1>{{score}}</h1>
      </div>
      {{score_label}}
    </div>
    <div class="col-md-4">
        <div>
            <h1>{{base}}</h1>
        </div>
        <div>
            BASE
        </div>
    </div>
    <div class="col-md-4">
        <div>
            <h1>{{demographic}}</h1>
        </div>
        <div>
          DEMOGRAPHIC
        </div>
    </div>
    <div class="col-md-4">
        <div>
            <h1>{{bank}}</h1>
        </div>
        <div>
            BANK
        </div>
    </div>
  </div>

  <div class="row">
      <div >
          <div>
              <h3>SCORING HISTORY</h3>
          </div>
          <div>
              GRAPHIC
          </div>
      </div>
  </div>

  <div class="row">
      <div >
          <div>
              <h3>SUMMARY</h3>
          </div>
          <div>
              DESCRIPTION
          </div>
      </div>
  </div>

</div>

