import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AffiliatesService } from '../../../services/affiliates.service';
import { ErrorMessageService } from '../../../../shared/error-message.service';

@Component({
    selector: 'app-affiliate-information',
    templateUrl: './affiliate-information.component.html',
    styleUrls: ['./affiliate-information.component.scss']
})
export class AffiliateInformationComponent implements OnInit {
    id: number = null;
    affiliate: any = {};
    sources: any = [];
    modalReference: NgbModalRef;

    form;
    modalTitle;
    sending: boolean = false;
    channelApiId = 2;

    affiliateModel = {
        information: [
            { label: 'Company Name', type: 'text', model: 'name', value: '' },
            { label: 'VAT NUmber', type: 'text', model: 'information_cif', value: '' },
            { label: 'Address', type: 'text', model: 'information_address', value: '' },
            { label: 'Zip Code', type: 'text', model: 'information_zip_code', value: '' },
            { label: 'City', type: 'text', model: 'information_city', value: '' },
            { label: 'Country', type: 'text', model: 'information_country', value: '' }
        ],
        contacts: [
            { label: 'Name', type: 'text', model: 'contact_one_name', value: '' },
            { label: 'Phone Mobile', type: 'tel', model: 'contact_one_mobile_phone', value: '' },
            { label: 'Email', type: 'email', model: 'contact_one_email', value: '' },
            { label: 'Role', type: 'text', model: 'contact_one_role', value: '' },
            { label: 'Name', type: 'text', model: 'contact_two_name', value: '' },
            { label: 'Phone Mobile', type: 'tel', model: 'contact_two_mobile_phone', value: '' },
            { label: 'Email', type: 'email', model: 'contact_two_email', value: '' },
            { label: 'Role', type: 'text', model: 'contact_two_role', value: '' },
            { label: 'Name', type: 'text', model: 'contact_three_name', value: '' },
            { label: 'Phone Mobile', type: 'tel', model: 'contact_three_mobile_phone', value: '' },
            { label: 'Email', type: 'email', model: 'contact_three_email', value: '' },
            { label: 'Role', type: 'text', model: 'contact_three_role', value: '' }
        ],
        terms: [
            { label: 'Lead Condition', type: 'text', model: 'term_lead_condition', value: '' },
            { label: 'Value', type: 'number', model: 'term_value', value: '' },
            { label: 'Payment Terms', type: 'text', model: 'term_payment', value: '' },
            { label: 'Active', type: 'checkbox', model: 'enabled', value: '' },
            { label: 'Statistics Email', type: 'email', model: 'term_statistic_email', value: '' }
        ]
    };

    constructor(
        private modal: NgbModal,
        private activatedRoute: ActivatedRoute,
        private affiliateService: AffiliatesService,
        private errorMessageService: ErrorMessageService
    ) {}

    ngOnInit() {
        this.id = +this.activatedRoute.snapshot.params.id;

        if (this.id) {
            this.getAffiliate(this.id);
            this.affiliateService.getChannels();
        }
    }

    getAffiliate(id) {
        this.affiliateService
            .showAffiliate(id)
            .toPromise()
            .then(affiliate => {
                if (Object.keys(affiliate).length > 0) {
                    this.affiliate = affiliate;
                    if (affiliate.sources.length > 0) {
                        this.sources = affiliate.sources;
                    }
                }
            });
    }

    openEdit(edit, model) {
        this.modalReference = this.modal.open(edit, { size: 'lg', backdrop: 'static' });

        this.modalTitle = model.charAt(0).toUpperCase() + model.slice(1);
        this.form = this.affiliateModel[model];

        this.form.forEach(item => {
            item.value = this.affiliate[item.model];
        });

        this.modalReference.result.then(
            result => {
                this.resetEditModal();
            },
            reason => {
                this.resetEditModal();
            }
        );
    }

    updateAffiliate() {
        this.sending = true;

        let data = {};

        this.form.forEach(item => {
            data[item.model] = item.value || '';
        });

        this.affiliateService
            .updateAffiliate(this.id, data)
            .toPromise()
            .then(
                response => {
                    if (response) {
                        setTimeout(
                            function(self) {
                                self.errorMessageService.showSuccess('Affiliate updated succesfully!');
                                self.modalReference.close();

                                const affiliate = self.affiliate;
                                self.affiliate = { ...affiliate, ...data };
                                self.sending = false;
                            },
                            600,
                            this
                        );
                    }
                },
                error => {
                    if (error.error.error) {
                        this.errorMessageService.showError(error.error.error.show_message);
                        setTimeout(
                            function(self) {
                                self.sendingPayment = false;
                            },
                            600,
                            this
                        );
                        this.sending = false;
                    }
                }
            );
    }

    resetEditModal() {
        this.form = [];
        this.modalTitle = '';
    }

    isApiType(channelId: any): boolean{
        return this.channelApiId === channelId;
    }

    getChannelName(channelId: any): string
    {
        const channel = this.affiliateService.channels.find((ch) => ch.id === channelId);
        if(channel){
            return channel.name;
        }

        return '';
    }

    getActionType(actionTypeId: any): string {
        const actionType = this.affiliateService.actionTypes
            .find((type) => type.id === actionTypeId);
        if(actionType){
            return actionType.name;
        }

        return '';
    }
}
