import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidBacc][formControlName],[ValidBacc][formControl],[ValidBacc][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidBaccDirective)}]
})
export class ValidBaccDirective implements Validator {

  constructor() { }

	validate(input: AbstractControl):{[key: string]:any} {
	  	//Se pasa a Mayusculas

	  	if (input == null) {

		    var IBAN = input.value.toString().toUpperCase();

		    console.log(IBAN);
		    
		    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena
		    console.log('IBAN'+IBAN);
		    var letra1,letra2,num1,num2;
		    var isbanaux;
		    var numeroSustitucion;
		    //La longitud debe ser siempre de 24 caracteres
		    if (IBAN.length != 24) {
		    	console.log('not lenght');
		        return {appValidBacc : false};
		    }

		    console.log('Iban length <> 24');

		    // Se coge las primeras dos letras y se pasan a números
		    letra1 = IBAN.substring(0, 1);
		    letra2 = IBAN.substring(1, 2);
		    num1 = this.getnumIBAN(letra1);
		    num2 = this.getnumIBAN(letra2);
		    //Se sustituye las letras por números.
		    isbanaux = String(num1) + String(num2) + IBAN.substring(2);

		    console.log('letras por numeros'+isbanaux);
		    // Se mueve los 6 primeros caracteres al final de la cadena.
		    isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6);

		    //Se calcula el resto, llamando a la función modulo97, definida más abajo
		    var resto = this.modulo97(isbanaux);
		    if (resto === '1'){
		        return null;
		    }else{
		        return {appValidBacc : false};
		    }
		}

	  	

  	}

	modulo97(iban) {
	    var parts = Math.ceil(iban.length/7);
	    var remainer = "";

	    for (var i = 1; i <= parts; i++) {
	        remainer = String(parseFloat(remainer+iban.substr((i-1)*7, 7))%97);
	    }

	    return remainer;
	}

	getnumIBAN(letra) {
		var ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	    return ls_letras.search(letra) + 10;
	}

	}
