<div class="card mb-3">
    <div class="card-header"><h5 class="card-header-title">Customer Loans</h5></div>
    <div class="card-body">
        <div class="margin-top-20 table-responsive">
            <table class="table table-condensed table-hover">
                <thead>
                    <th width="35%">Code</th>
                    <th width="30%">Status</th>
                    <th width="35%">Reason</th>
                </thead>
                <tbody>
                    <tr *ngFor="let line of credit_lines?.data">
                        <td width="35%">
                            <a
                                target="_blank"
                                href="/backoffice/loans/overview/{{ line?.lead_id }}/loanInfo/{{ line?.lead_id }}"
                                >{{ line.code_str }}</a
                            >
                        </td>
                        <td width="30%">{{ line.status.name }}</td>
                        <td width="35%">{{ line.reason?.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
