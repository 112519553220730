import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-out-of-service',
  templateUrl: './out-of-service.component.html',
  styleUrls: ['./out-of-service.component.css']
})
export class OutOfServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
