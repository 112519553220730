import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-view-user',
  templateUrl: './setting-view-user.component.html',
  styleUrls: ['./setting-view-user.component.scss']
})
export class SettingViewUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
