<div *appHasPermissions="permissionsArray">
    <div [ngClass]="{ 'mb-4': selectedPageName === 'all' }">
        <h2>{{ title }}</h2>
        <a
            *ngIf="selectedPageName === 'all'"
            [href]="exportLink"
            (click)="getExportLink($event)"
            [ngClass]="{ disabled: loadingExport }"
            >{{ loadingExport ? 'Loading. Please Wait...' : 'Export' }}</a
        >
    </div>
    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
    <div class="table-responsive table-hover">
        <table class="table">
            <!-- row -->
            <thead>
                <tr>
                    <!-- column -->
                    <th>Loan</th>
                    <th *ngIf="selectedPageName === 'due'">Due Date</th>
                    <th *ngIf="selectedPageName !== 'rejected'">Created</th>
                    <th *ngIf="selectedPageName === 'rejected'">Rejected</th>
                    <th
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        Activated
                    </th>
                    <th
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'active' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        Status
                    </th>
                    <th>DNI/NIE</th>
                    <th>Customer Name</th>
                    <th *ngIf="selectedPageName === 'rejected'">Reason</th>
                    <th *ngIf="selectedPageName != 'rejected'">Product</th>
                    <th>Amount</th>
                    <th
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        Debt Left
                    </th>
                    <th
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        Quotas refused
                    </th>
                    <th
                        *ngIf="
                            selectedPageName === 'all' ||
                            selectedPageName === 'active' ||
                            selectedPageName === 'rejected'
                        "
                    >
                        Who
                    </th>
                    <th>Source</th>
                    <th>Medium</th>
                    <th>Campaign</th>
                    <th *ngIf="selectedPageName === 'due'">Debt Collector</th>
                    <th *ngIf="selectedPageName === 'pendingActivation'">
                        Check
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <!-- Loan code -->
                    <td>
                        <input
                            size="20"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Code"
                            name="code_str"
                            [(ngModel)]="filters.code_str"
                        />
                    </td>
                    <!-- Due at -->
                    <td *ngIf="selectedPageName === 'due'">
                        <app-daterange-filter
                            fieldName="due_status_date"
                            [start]="filters.due_status_date_start"
                            [end]="filters.due_status_date_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <!-- Created at -->
                    <td *ngIf="selectedPageName !== 'rejected'">
                        <app-daterange-filter
                            fieldName="created_at"
                            [start]="filters.created_at_start"
                            [end]="filters.created_at_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <!-- Rejected at -->
                    <td *ngIf="selectedPageName === 'rejected'">
                        <app-daterange-filter
                            fieldName="rejected_date"
                            [start]="filters.rejected_date_start"
                            [end]="filters.rejected_date_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <!-- Activated at -->
                    <td
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        <app-daterange-filter
                            fieldName="activation_date"
                            [start]="filters.activation_date_start"
                            [end]="filters.activation_date_end"
                            (changeDate)="onChangeDate($event)"
                        >
                        </app-daterange-filter>
                    </td>
                    <!-- Status -->
                    <td
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'active' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="status_id"
                            [(ngModel)]="filters.status_id"
                        >
                            <option value>Select status</option>
                            <option
                                *ngFor="
                                    let key of credit_line_status_filter | keys
                                "
                                value="{{ credit_line_status_filter[key].id }}"
                            >
                                {{ credit_line_status_filter[key].name }}
                            </option>
                        </select>
                    </td>
                    <!-- DNI/NIE -->
                    <td>
                        <input
                            size="10"
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="DNI / NIE"
                            name="personalid"
                            [(ngModel)]="filters.personalid"
                        />
                    </td>
                    <!-- Customer Name -->
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Full name"
                            name="full_name"
                            [(ngModel)]="filters.full_name"
                        />
                    </td>
                    <!-- Reason -->
                    <td *ngIf="selectedPageName === 'rejected'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="reason_id"
                            [(ngModel)]="filters.reason_id"
                        >
                            <option value>Select Reason</option>
                            <option
                                *ngFor="let reason of reasons"
                                value="{{ reason?.id }}"
                            >
                                {{ reason?.name }}
                            </option>
                        </select>
                    </td>
                    <!-- Product -->
                    <td *ngIf="selectedPageName != 'rejected'">
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Product"
                            name="product_weeks"
                            [(ngModel)]="filters.product_weeks"
                        />
                    </td>
                    <!-- Amount -->
                    <td>
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Amount"
                            name="amount"
                            [(ngModel)]="filters.amount"
                        />
                    </td>
                    <!-- Debt Left -->
                    <td
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        <app-dropdown-range
                            [start]="filters.debt_left_start"
                            [end]="filters.debt_left_end"
                            (changeRange)="onChangeRange($event)"
                        ></app-dropdown-range>
                    </td>
                    <!-- Quotas refused -->
                    <td
                        *ngIf="
                            selectedPageName != 'new' &&
                            selectedPageName != 'pendingActivation' &&
                            selectedPageName != 'rejected'
                        "
                    >
                        <input
                            type="number"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Quotas"
                            name="quota_refused_count"
                            [(ngModel)]="filters.quota_refused_count"
                        />
                    </td>
                    <!-- Who -->
                    <td
                        *ngIf="
                            selectedPageName === 'all' ||
                            selectedPageName === 'active' ||
                            selectedPageName === 'rejected'
                        "
                    >
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="who"
                            [(ngModel)]="filters.who"
                        >
                            <option value>Added by</option>
                            <option
                                *ngFor="let user of users?.actived"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                            <option *ngIf="users?.blocked.length > 0" disabled>
                                ──────────
                            </option>
                            <option
                                *ngFor="let user of users?.blocked"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                        </select>
                    </td>
                    <!-- Source -->
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Source"
                            name="source"
                            [(ngModel)]="filters.source"
                        />
                    </td>
                    <!-- Medium -->
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Medium"
                            name="medium"
                            [(ngModel)]="filters.medium"
                        />
                    </td>
                    <!-- Campaign -->
                    <td>
                        <input
                            type="text"
                            (keyup.enter)="getDataOnFilterSubmit()"
                            placeholder="Campaign"
                            name="campaign"
                            [(ngModel)]="filters.campaign"
                        />
                    </td>
                    <td *ngIf="selectedPageName === 'due'">
                        <select
                            (change)="getDataOnFilterSubmit()"
                            name="debt_collector_id"
                            [(ngModel)]="filters.debt_collector_id"
                        >
                            <option value>Select Debt Collector</option>
                            <option
                                *ngFor="let user of users?.actived"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                            <option *ngIf="users?.blocked.length > 0" disabled>
                                ──────────
                            </option>
                            <option
                                *ngFor="let user of users?.blocked"
                                value="{{ user?.id }}"
                            >
                                {{ user?.name }}
                            </option>
                        </select>
                    </td>
                </tr>

                <ng-template [ngIf]="tableData?.data.length > 0 && !loading">
                    <tr
                        *ngFor="let item of tableData?.data"
                        [ngClass]="item?.is_customer ? 'table-success' : ''"
                    >
                        <td>
                            <a
                                class="miLineaFontColor"
                                (click)="goto(item.lead_id)"
                            >
                                {{ item.code_str }}
                            </a>
                        </td>
                        <td *ngIf="selectedPageName === 'due'">
                            {{ item?.due_status_date }}
                        </td>
                        <td *ngIf="selectedPageName !== 'rejected'">
                            {{ item?.created_at }}
                        </td>
                        <td *ngIf="selectedPageName === 'rejected'">
                            {{ item?.rejected_date }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName != 'new' &&
                                selectedPageName != 'pendingActivation' &&
                                selectedPageName != 'rejected'
                            "
                        >
                            {{ item?.activation_date }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName != 'new' &&
                                selectedPageName != 'pendingActivation' &&
                                selectedPageName != 'active' &&
                                selectedPageName != 'rejected'
                            "
                        >
                            {{ item?.status }}
                        </td>
                        <td>{{ item?.personalid }}</td>
                        <td>{{ item?.full_name }}</td>
                        <td *ngIf="selectedPageName === 'rejected'">
                            {{ item?.reason }}
                        </td>
                        <td *ngIf="selectedPageName != 'rejected'">
                            {{ item?.product_weeks }}
                        </td>
                        <td>
                            {{ item?.amount | currency: 'EUR' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName != 'new' &&
                                selectedPageName != 'pendingActivation' &&
                                selectedPageName != 'rejected'
                            "
                        >
                            {{ item?.debt_left | currency: 'EUR' }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName != 'new' &&
                                selectedPageName != 'pendingActivation' &&
                                selectedPageName != 'rejected'
                            "
                        >
                            {{ item?.quotas_refused }}
                        </td>
                        <td
                            *ngIf="
                                selectedPageName === 'all' ||
                                selectedPageName === 'active' ||
                                selectedPageName === 'rejected'
                            "
                        >
                            {{ item?.who }}
                        </td>
                        <td>{{ item?.source }}</td>
                        <td>{{ item?.medium }}</td>
                        <td>{{ item?.campaign }}</td>
                        <td *ngIf="selectedPageName === 'due'">
                            {{ item?.debt_collector }}
                        </td>
                        <td *ngIf="selectedPageName === 'pendingActivation'">
                            <i
                                class="fa fa-cog"
                                (click)="check(item.lead_id, item.id)"
                            ></i>
                            {{ item?.actived_user ? item?.actived_user : '-' }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="!tableData?.data.length && !loading">
                    <tr class="without_hover">
                        <td colspan="3">
                            <p class="py-5 mb-0">Nothing Found</p>
                        </td>
                    </tr>
                </ng-template>
                <tr *ngIf="loading" class="without_hover">
                    <td colspan="3">
                        <p class="py-5 mb-0">
                            <img
                                width="30px"
                                height="30px"
                                src="assets/img/backoffice/loading.gif"
                            />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-pager
        [perPage]="tableData?.per_page"
        [currentPage]="tableData?.current_page"
        [from]="tableData?.from"
        [to]="tableData?.to"
        [total]="tableData?.total"
        (changePage)="getDataOnPageChange($event)"
    >
    </app-pager>
</div>
