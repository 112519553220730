import {ErrorMessageService} from '../../../../shared/error-message.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-settings-create-product',
  templateUrl: './settings-create-product.component.html',
  styleUrls: ['./settings-create-product.component.scss']
})
export class SettingsCreateProductComponent implements OnInit {

  productForm: FormGroup = null;
  product_id: any = null;

  constructor(private fb: FormBuilder, 
    private errorMessage: ErrorMessageService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private productService: ProductService) {

    this.productForm = fb.group({
      name: ['', Validators.required],
      min: ['', Validators.required],
      max: ['', Validators.required],
      daily_interest_rate: ['', Validators.required], 
      withdrawal_cost_rate: ['', Validators.required], 
      month_minimun_payment_amount: ['', Validators.required], 
      late_fee_amount: ['', Validators.required], 
      max_limit_approved: ['', Validators.required], 
});

}
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if(params['id']!=null){
        this.product_id = +params['id'];
        this.productService.get(this.product_id).subscribe(
          product => {
            if(Object.values(product).length){
              this.productForm.controls.name.setValue(product.name);
              this.productForm.controls.min.setValue(product.min);
              this.productForm.controls.max.setValue(product.max);
              this.productForm.controls.daily_interest_rate.setValue(product.daily_interest_rate);
              this.productForm.controls.withdrawal_cost_rate.setValue(product.withdrawal_cost_rate);
              this.productForm.controls.month_minimun_payment_amount.setValue(product.month_minimun_payment_amount);
              this.productForm.controls.late_fee_amount.setValue(product.late_fee_amount);
              this.productForm.controls.max_limit_approved.setValue(product.max_limit_approved);
            }
          }
        );
      }
    });
  }

  onFormSubmit()
  {
    if(this.product_id){
      if(this.productForm.valid){
      this.productService.edit(this.product_id, this.productForm.value).subscribe(
        resp => {
          if(resp['accepted'] === true){
            this.errorMessage.showSuccess(`El Producto ${resp.product.name} ha sido editado`);
            this.router.navigate(['/backoffice/settings/product_overview/product_list']);
          }
        }, 
        error => { 
          this.errorMessage.showError(error.error.error.show_message);
        }
      );
      }
    }else{
      if(this.productForm.valid){
      this.productService.create(this.productForm.value).subscribe(
        resp => {
          if(resp['accepted'] === true){
            this.errorMessage.showSuccess(`El Producto ${resp.product.name} ha sido creado`);
            this.productForm.reset();
          }
        }, 
        error => { 
          this.errorMessage.showError(error.error.error.show_message);
        }
      );
        }
    }
  }

  
}
