import { Directive, forwardRef, Attribute } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidEmail][formControlName],[appValidEmail][formControl],[appValidEmail][ngModel]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => ValidEmailDirective)}]
})
export class ValidEmailDirective {

  constructor(@Attribute('validateEqual')  public validateEqual: string ) {}

validate(input: AbstractControl):{[key: string]:any} {

  	// self value (email comprobation)
    let v = input.value;

    // control value (email)
    let e = input.root.value.email;

    // value  equal
    if (e && v === e) {
       return null;
    }else{
    	return {appValidEmail: false};
    }

  
}
  
}

 


